import React from "react";
import { Link } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import "../styles/NavBar.css";
import useAuth from "../hooks/useAuth";
import SkipNavigation from "./SkipNavigation";

function SimpleNavBar(props: RouteComponentProps) {
  const { getAuthenticationState } = useAuth();

  const isLoggedIn = getAuthenticationState();

  return (
    <>
      <SkipNavigation />
      <nav className="NavBar NavBar--white NavBar--simple">
        <Link
          to="/home/"
          data-testid="navbar-title"
          className="NavBar__title NavBar__title--white NavBar__title--simple"
        >
          Cali Skills
        </Link>
        <ul className="NavBar__list">
          {!isLoggedIn && (
            <li className="NavBarItem__link NavBarItem__link--white">
              <Link to="/login">Log in</Link>
            </li>
          )}
          <li className="NavBarItem__link NavBarItem__link--white">
            <Link to="/home/">Home</Link>
          </li>
          {isLoggedIn && (
            <>
              <li className="NavBarItem__link NavBarItem__link--white">
                <Link to="/workouts">Workouts</Link>
              </li>
              <li className="NavBarItem__link NavBarItem__link--white">
                <Link to="/exercises">Exercises</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
}

export default SimpleNavBar;
