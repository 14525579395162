import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import { BeforeInstallPromptEvent } from "../../typings";
import Button from "./Button";

function InstallButton() {
  const deferredPrompt = useRef<BeforeInstallPromptEvent | null>(null);
  const [installButtonVisible, setInstallButtonVisibilityState] = useState(
    false
  );

  function handleEvent(e: BeforeInstallPromptEvent) {
    e.preventDefault();
    deferredPrompt.current = e;
    setInstallButtonVisibilityState(true);
  }

  useEffectOnce(() => {
    // @ts-ignore
    window.addEventListener("beforeinstallprompt", handleEvent);

    return function cleanup() {
      // @ts-ignore
      window.removeEventListener("beforeinstallprompt", handleEvent);
    };
  });

  function handleClick() {
    return deferredPrompt.current!.prompt();
  }

  if (!installButtonVisible) return null;

  return (
    <>
      <div style={{ padding: "8px", textAlign: "left" }}>
        <Button size="small" handleClick={handleClick} colorVariant="light">
          Install
        </Button>
      </div>
    </>
  );
}

export default InstallButton;
