import React from "react";
import { Link } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import "../styles/NavBar.css";
import NavBarItems from "./NavBarItems";
import SkipNavigation from "./SkipNavigation";

function NavBar(props: RouteComponentProps) {
  return (
    <div style={{ position: "relative" }}>
      <SkipNavigation />
      <nav className="NavBar">
        <Link
          to="/home/"
          data-testid="navbar-title"
          className="NavBar__title"
        >
          Cali Skills
        </Link>
        <ul className="NavBar__list">
          <NavBarItems />
        </ul>
      </nav>
    </div>
  );
}

export default NavBar;
