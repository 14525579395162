import React from "react";
import { Link } from "gatsby";
import useAuth from "../hooks/useAuth";
import Dropdown from "./Dropdown";
import Button from "./Button";
import "../styles/NavBarItems.css";

function NavBarItems() {
  const { getAuthenticationState, login } = useAuth();
  const isAuthenticated = getAuthenticationState();

  return (
    <React.Fragment>
      {!isAuthenticated && (
        <li className="NavBarItem__button">
          <Button
            size="small"
            handleClick={() => login(window.location.pathname)}
          >
            Sign up
          </Button>
        </li>
      )}

      <li className="NavBarItem__link">
        <Link activeClassName="NavBarItem--active" to="/home/">
          Home
        </Link>
      </li>

      <li className="NavBarItem__link">
        <Link activeClassName="NavBarItem--active" to="/skill-tree/">
          Progressions
        </Link>
      </li>
      {isAuthenticated && (
        <li className="NavBarItem__link">
          <Link activeClassName="NavBarItem--active" to="/workouts/">
            Workouts
          </Link>
        </li>
      )}
      <li className="NavBarItem__link">
        <Link activeClassName="NavBarItem--active" to="/exercises/">
          Exercises
        </Link>
      </li>
      <li>
        <Dropdown />
      </li>
    </React.Fragment>
  );
}

export default NavBarItems;
