import { useQuery } from "react-query";
import { SubscriptionData } from "../models";
import useAuth from "./useAuth";
import getUserSubscription from "../api/getUserSubscription";

type TKey = "subscription";

interface TVariables {
  userId: string;
  accessToken: string;
}

function useGetUserSubscription() {
  const {
    getUserId,
    getAccessToken,
    tokenRenewalComplete,
    getAuthenticationState
  } = useAuth();

  const isUserAuthenticated = getAuthenticationState();

  const shouldFetch = tokenRenewalComplete && isUserAuthenticated;

  const userId = shouldFetch ? getUserId() : "";
  const accessToken = shouldFetch ? getAccessToken() : "";

  const { status, data, error } = useQuery<
    SubscriptionData,
    [TKey, TVariables]
  >(
    [
      "subscription",
      {
        userId,
        accessToken
      }
    ],
    getUserSubscription,
    {
      enabled: shouldFetch,
      staleTime: 1000 * 60
    }
  );

  return {
    status,
    data,
    error
  };
}

export default useGetUserSubscription;
