import React from "react";
import { Link } from "gatsby";
import useAuth from "../hooks/useAuth";
import InstallButton from "./InstallButton";
import "../styles/DropdownMenu.css";
import Button from "./Button";
import useIsSubscriptionActive from "../hooks/useIsSubscriptionActive";

function DropdownMenu() {
  const { logout, getAuthenticationState } = useAuth();
  const isSubscriptionActive = useIsSubscriptionActive();
  const isAuthenticated = getAuthenticationState();
  return (
    <div className="DropdownMenu">
      <Link to="/equipment/">Equipment</Link>
      <Link to="/profile/">Your Profile</Link>
      <Link to="/about/">About Us</Link>
      <Link to="/release-notes/">Latest Releases</Link>
      {isAuthenticated && !isSubscriptionActive && (
        <Link to="/pricing">Upgrade</Link>
      )}
      <InstallButton />
      {isAuthenticated && (
        <div style={{ padding: "8px", textAlign: "left" }}>
          <Button size="small" handleClick={logout} colorVariant="light">
            Log out
          </Button>
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;
