import React from "react";
import Tippy from "@tippy.js/react";
import DropdownMenu from "./DropdownMenu";
import "../styles/Dropdown.css";

function Dropdown() {
  return (
    <div className="Dropdown">
      <Tippy
        className="Dropdown__tooltip"
        zIndex={20000}
        interactive
        content={<DropdownMenu />}
      >
        <div tabIndex={0} className="Dropdown__caret">▲</div>
      </Tippy>
    </div>
  );
}

export default Dropdown;
