import React from "react";
import { Router } from "@reach/router";
import NavBar from "./NavBar";
import SimpleNavBar from "./SimpleNavBar";

function NavRoutes() {
  return (
    <Router>
      <SimpleNavBar path="/" />
      <SimpleNavBar path="/pricing" />
      <NavBar default />
    </Router>
  );
}

export default NavRoutes;
