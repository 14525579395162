import React from "react";
import "../styles/SkipNavigation.css";

function SkipNavigation() {
  return (
    <a className="SkipNavigation" tabIndex={0} href="#main-content">
      Skip Navigation
    </a>
  );
}

export default SkipNavigation;
